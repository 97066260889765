import React from 'react';
import { Layout, Text } from '../redesign';
import { default as SEO } from '../components/SEO';
import { graphql } from 'gatsby';
import { SanityMiscellaneousPage } from '../../graphql-types';

interface SanityMiscellaneousPageProps {
  data: {
    sanityMiscellaneousPage: SanityMiscellaneousPage;
  };
}

const extractContent = (
  identifier: string,
  contents: SanityMiscellaneousPage['contents']
): string | null => {
  const result = contents.find(content => content.identifier === identifier);
  // We are rturning only strings for now
  if (typeof result?.content === 'string') {
    return result?.content;
  }
  return null;
};

export default function ReferError({ data }: SanityMiscellaneousPageProps) {
  const contents = data.sanityMiscellaneousPage.contents;
  const heading = extractContent('heading', contents);
  const body = extractContent('body', contents);

  return (
    <Layout footerContentKey="burnout">
      <SEO pageTitle={heading} pageDescription={body} />
      <div className="my-lg text-center">
        {heading && (
          <Text variant="h1a" className="mb-lg">
            {heading}
          </Text>
        )}
        {body && <Text variant="p1a">{body}</Text>}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query MyQuery {
    sanityMiscellaneousPage(identifier: { eq: "refer-error" }) {
      contents {
        ... on SanityMiscellaneousPageStringContent {
          content
          identifier
        }
      }
    }
  }
`;
